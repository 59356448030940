const theme = {
  globalWidth: "1280px",
  colors: {
    footer: "#9d9d9c",
    sideBarLink: "#3a4649",
    body: "#FFFFFF",
    globalFont: "#212529",
    heading: "black",
    navLink: "#FFFFFF",
    secondary: "#3a4649",
    activeLink: "#c4281d",
    hoverLink: "#d9d9d9",
    lightHoverLink: "#575757",
    footerFont: "white",
    footerHeading: "white",
    line: "rgb(229, 229, 229)",
    tableBackground: "#D6D6D6",
    tableBorder: "#6B6B6B",
    blockBackground: "rgba(21, 21, 21, 0.8)",
    blogNewsDate: "white",
    
  },
}

export default theme
