import React from "react"
import { ThemeProvider } from "@emotion/react"
import media from "./media"
import theme from "./theme"

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={{ ...theme, ...media }}>{children}</ThemeProvider>
  )
}

export default Theme
