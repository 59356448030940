// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contacts-en-js": () => import("./../../../src/pages/contacts-en.js" /* webpackChunkName: "component---src-pages-contacts-en-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-portfolio-general-plan-js": () => import("./../../../src/pages/portfolio/general-plan.js" /* webpackChunkName: "component---src-pages-portfolio-general-plan-js" */),
  "component---src-pages-portfolio-illumination-plan-js": () => import("./../../../src/pages/portfolio/illumination-plan.js" /* webpackChunkName: "component---src-pages-portfolio-illumination-plan-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-strategic-plan-js": () => import("./../../../src/pages/portfolio/strategic-plan.js" /* webpackChunkName: "component---src-pages-portfolio-strategic-plan-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-concepts-js": () => import("./../../../src/templates/concepts.js" /* webpackChunkName: "component---src-templates-concepts-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

